<template>
    <div>
        查询系统
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>